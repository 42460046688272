function _typeof(o){"@babel/helpers - typeof";return _typeof="function"==typeof Symbol&&"symbol"==typeof Symbol.iterator?function(o){return typeof o;}:function(o){return o&&"function"==typeof Symbol&&o.constructor===Symbol&&o!==Symbol.prototype?"symbol":typeof o;},_typeof(o);}function ownKeys(e,r){var t=Object.keys(e);if(Object.getOwnPropertySymbols){var o=Object.getOwnPropertySymbols(e);r&&(o=o.filter(function(r){return Object.getOwnPropertyDescriptor(e,r).enumerable;})),t.push.apply(t,o);}return t;}function _objectSpread(e){for(var r=1;r<arguments.length;r++){var t=null!=arguments[r]?arguments[r]:{};r%2?ownKeys(Object(t),!0).forEach(function(r){_defineProperty(e,r,t[r]);}):Object.getOwnPropertyDescriptors?Object.defineProperties(e,Object.getOwnPropertyDescriptors(t)):ownKeys(Object(t)).forEach(function(r){Object.defineProperty(e,r,Object.getOwnPropertyDescriptor(t,r));});}return e;}function _defineProperty(e,r,t){return(r=_toPropertyKey(r))in e?Object.defineProperty(e,r,{value:t,enumerable:!0,configurable:!0,writable:!0}):e[r]=t,e;}function _toPropertyKey(t){var i=_toPrimitive(t,"string");return"symbol"==_typeof(i)?i:i+"";}function _toPrimitive(t,r){if("object"!=_typeof(t)||!t)return t;var e=t[Symbol.toPrimitive];if(void 0!==e){var i=e.call(t,r||"default");if("object"!=_typeof(i))return i;throw new TypeError("@@toPrimitive must return a primitive value.");}return("string"===r?String:Number)(t);}import{setCookie as cookiesNextSetCookie}from"cookies-next";export var defaultCookieExpiry=60*60*12;// 12 hours (60 sec * 60 min * 12 hr)
/**
 * For standardization, please create wrapper functions when consuming cookies
 * get/set/delete. Check out the technical design in Confluence or
 * /lib/controllers/verify/cookies for an example.
 *
 * @param pagePath The URL path of the area you'll be using the cookies for
 * @param key The key of the key-value pair that you're storing in cookies
 * @param value The value of the key-value pair that you're storing in cookies
 * @param options Used when accessing cookies on server-side rendering. Refer to cookies-next documentation for more information.
 * @param maxAge The max lifespan of the cookie in seconds. Defaults to our NextAuth's expiry.
 */export var setCookie=function setCookie(_ref){var pagePath=_ref.pagePath,key=_ref.key,value=_ref.value,options=_ref.options,maxAge=_ref.maxAge;// Note: Setting secure = true for Safari will cause cookies to not be set because locahost is not a secure domain
// However, Chrome and Firefox will set the cookie with secure = true
var secure=true;cookiesNextSetCookie(key,value,_objectSpread(_objectSpread({},options),{},{path:pagePath,maxAge:maxAge||defaultCookieExpiry,secure:secure}));};